.generate-key__buttons-wrapper {
  display: flex;
  flex-flow: row wrap
}

.generate-key__submit-button {
  background-color: var(--headsColor);
  border: 1px solid var(--commentColor);
  color: var(--baseColor);
  display: flex;
  flex-basis: calc(66.6% - 0.06667rem);
  flex-grow: 0;
  flex-shrink: 0;
  height: 2.6rem;
  max-width: calc(66.6% - 0.06667rem);
  text-align: center;
  width: calc(66.6% - 0.06667rem);
  align-items: center;
  justify-content: center;
}

.generate-key__submit-button:nth-child(1n) {
  margin-left: 0;
  margin-right: 0.2rem;
}

.generate-key__submit-button:last-child {
  margin-right: 0;
}

.generate-key__submit-button:nth-child(3n) {
  margin-left: auto;
  margin-right: 0;
}

.generate-key__submit-button:focus:not(:focus-visible) {
  outline: none;
}

.generate-key__submit-button:active {
  border-width: 2px;
}

.generate-key__submit-button--disabled {
  background-color: var(--commentColor);
}

.generate-key__reset-button {
  background-color: var(--bgColor);
  border: 1px solid var(--commentColor);
  color: var(--baseColor);
  display: flex;
  flex-basis: calc(33.3% - 0.13333rem);
  flex-grow: 0;
  flex-shrink: 0;
  height: 2.6rem;
  max-width: calc(33.3% - 0.13333rem);
  text-align: center;
  width: calc(33.3% - 0.13333rem);
  align-items: center;
  justify-content: center;
}

.generate-key__reset-button:nth-child(1n) {
  margin-left: 0;
  margin-right: 0.2rem;
}

.generate-key__reset-button:last-child {
  margin-right: 0;
}

.generate-key__reset-button:nth-child(3n) {
  margin-left: auto;
  margin-right: 0;
}

.generate-key__reset-button:focus:not(:focus-visible) {
  outline: none;
}

.generate-key__reset-button--disabled {
  background-color: var(--commentColor);
}

.generate-key__reset-button:active {
  border-width: 2px;
}
