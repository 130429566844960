.generate-pass__reset-wrapper {
  display: flex;
  flex-flow: row wrap;
  height: 1.2rem;
  align-items: center;
  justify-content: flex-end;
}

.generate-pass__reset-link {
  flex-basis: calc(66.6% - 10px);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(66.6% - 10px);
  padding: 0 1rem .5rem 0;
  text-align: right;
  width: calc(66.6% - 10px);
}

.generate-pass__reset-link:nth-child(1n) {
  margin-left: 0;
  margin-right: 30px;
}

.generate-pass__reset-link:last-child {
  margin-right: 0;
}

.generate-pass__reset-link:nth-child(3n) {
  margin-left: auto;
  margin-right: 0;
}

.generate-pass__buttons-wrapper {
  display: flex;
  flex-flow: row wrap;
}

.generate-pass__submit-button {
  background-color: var(--headsColor);
  border: 1px solid var(--commentColor);
  color: var(--baseColor);
  display: flex;
  flex-basis: calc(66.6% - 0.06667rem);
  flex-grow: 0;
  flex-shrink: 0;
  height: 2.6rem;
  max-width: calc(66.6% - 0.06667rem);
  text-align: center;
  width: calc(66.6% - 0.06667rem);
  align-items: center;
  justify-content: center;
}

.generate-pass__submit-button:nth-child(1n) {
  margin-left: 0;
  margin-right: 0.2rem;
}

.generate-pass__submit-button:last-child {
  margin-right: 0;
}

.generate-pass__submit-button:nth-child(3n) {
  margin-left: auto;
  margin-right: 0;
}

.generate-pass__submit-button:focus:not(:focus-visible) {
  outline: none;
}

.generate-pass__submit-button:active {
  border-width: 2px;
}

.generate-pass__submit-button--disabled {
  background-color: var(--commentColor);
}

.generate-pass__reset-button {
  background-color: var(--bgColor);
  border: 1px solid var(--commentColor);
  color: var(--baseColor);
  display: flex;
  flex-basis: calc(33.3% - 0.13333rem);
  flex-grow: 0;
  flex-shrink: 0;
  height: 2.6rem;
  max-width: calc(33.3% - 0.13333rem);
  text-align: center;
  width: calc(33.3% - 0.13333rem);
  align-items: center;
  justify-content: center;
}

.generate-pass__reset-button:nth-child(1n) {
  margin-left: 0;
  margin-right: 0.2rem;
}

.generate-pass__reset-button:last-child {
  margin-right: 0;
}

.generate-pass__reset-button:nth-child(3n) {
  margin-left: auto;
  margin-right: 0;
}

.generate-pass__reset-button:focus:not(:focus-visible) {
  outline: none;
}
.generate-pass__reset-button:active {
  border-width: 2px;
}
.generate-pass__reset-button--disabled {
  background-color: var(--commentColor);
}

.generate-pass__result-title {
  text-align: center;
  padding: 0.5rem 0;
}

.generate-pass__result-wrapper {
  display: flex;
  flex-flow: row wrap;
}

.generate-pass__result-inputs {
  flex-basis: calc(74.925% - 0.05rem);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(74.925% - 0.05rem);
  width: calc(74.925% - 0.05rem);
}

.generate-pass__result-inputs:nth-child(1n) {
  margin-left: 0;
  margin-right: 0.2rem;
}

.generate-pass__result-inputs:last-child {
  margin-right: 0;
}

.generate-pass__result-inputs:nth-child(4n) {
  margin-left: auto;
  margin-right: 0;
}

.generate-pass__result-password {
  width: 100%;
  font-size: 1.8rem;
  color: var(--inputColor);
  border-radius: 6px;
  border: 1px solid var(--inputBorder);
  background-color: var(--inputBg);
  box-sizing: border-box;
}

.generate-pass__result-password:focus:not(:focus-visible) {
  outline: none;
}

.generate-pass__password-visibility-input {
  margin: 0.5rem;
}

.generate-pass__password-visibility-label {
  vertical-align: middle;
}

.generate-pass__result-copy-button {
  background-color: var(--bgColor);
  border: 1px solid var(--commentColor);
  color: var(--baseColor);
  flex-basis: calc(24.975% - 0.15rem);
  flex-grow: 0;
  flex-shrink: 0;
  height: 3.3rem;
  max-width: calc(24.975% - 0.15rem);
  vertical-align: middle;
  width: calc(24.975% - 0.15rem);
}

.generate-pass__result-copy-button:nth-child(1n) {
  margin-left: 0;
  margin-right: 0.2rem;
}

.generate-pass__result-copy-button:last-child {
  margin-right: 0;
}

.generate-pass__result-copy-button:nth-child(4n) {
  margin-left: auto;
  margin-right: 0;
}

.generate-pass__result-copy-button:focus:not(:focus-visible) {
  outline: none;
}

.generate-pass__result-copy-button:active {
  border-width: 2px;
}
