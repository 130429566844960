.app-update {
  border: 1px solid var(--commentColor);
  display: flex;
  flex-flow: row wrap;
  margin: 0.6rem;
}

.app-update__title {
  display: flex;
  flex-basis: calc(66.6% - 10px);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(66.6% - 10px);
  text-align: center;
  width: calc(66.6% - 10px);
  align-items: center;
  justify-content: center;
}

.app-update__title:nth-child(1n) {
  margin-left: 0;
  margin-right: 30px;
}

.app-update__title:last-child {
  margin-right: 0;
}

.app-update__title:nth-child(3n) {
  margin-left: auto;
  margin-right: 0;
}

.app-update__link-wrapper {
  background-color: var(--headsColor);
  display: flex;
  flex-basis: calc(33.3% - 20px);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: calc(33.3% - 20px);
  text-align: center;
  vertical-align: middle;
  width: calc(33.3% - 20px);
  align-items: center;
  justify-content: center;
}

.app-update__link-wrapper:nth-child(1n) {
  margin-left: 0;
  margin-right: 30px;
}

.app-update__link-wrapper:last-child {
  margin-right: 0;
}

.app-update__link-wrapper:nth-child(3n) {
  margin-left: auto;
  margin-right: 0;
}
