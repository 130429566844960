.spinner-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}

.spinner-cube-part {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}

.spinner-cube-part::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--baseColor);
  animation: spinner-cube-fold-cube-angle 1.6s infinite linear both;
  transform-origin: 100% 100%;
}

.spinner-cube-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}

.spinner-cube-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}

.spinner-cube-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}

.spinner-cube-cube2::before {
  animation-delay: 0.2s;
}

.spinner-cube-cube3::before {
  animation-delay: 0.4s;
}

.spinner-cube-cube4::before {
  animation-delay: 0.6s;
}

@keyframes spinner-cube-fold-cube-angle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
