@import 'normalize.css';

:root {
  --baseColor: #657b83;
  --bgColor: #fdf6e3;
  --headsColor: #eee8d5;
  --emphColor: #586e75;
  --commentColor: #93a1a1;
  --inputBg: #fefcf6;
  --inputBgDisabled: #93a1a1;
  --inputColor: #073642;
  --inputBorder: #93a1a1;
  --inputColorPlaceholder: #16a7cc;
}

:root.dark {
  --baseColor: #839496;
  --bgColor: #002b36;
  --headsColor: #073642;
  --emphColor: #93a1a1;
  --commentColor: #586e75;
  --inputBg: #073642;
  --inputBgDisabled: #586e75;
  --inputColor: #eee8d5;
  --inputBorder: #586e75;
  --inputColorPlaceholder: #c2ac68;
}

:root {
  font-size: 18px;
}

@media (min-width: 420px) and (max-width: 980px) {
  :root {
    font-size: calc( 18px + (22 - 18) * ( (100vw - 420px) / ( 980 - 420) ));
  }
}
@media (min-width: 980px) {
  :root {
    font-size: 22px;
  }
}

html {
  background-color: var(--headsColor);
  color: var(--baseColor);
}

body {
  font-family: system-ui;
}

a {
  color: #b58900;
}

a:hover {
  color: #cb4b16;
  text-decoration: none;
}

pre {
  font-size: 1rem;
  background-color: var(--bgColor);
  color: var(--baseColor);
  border: 1px solid var(--commentColor);
  padding: 1rem;
  box-shadow: 5px 5px 8px var(--headsColor);
  white-space: pre-wrap;
}
pre code {
  background-color: var(--bgColor);
}

h1, h2, h3, h4, h5, h6 {
  color: #859900;
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.3rem;
}

h6 {
  font-size: 1.15rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

:focus:not(:focus-visible) {
  outline: none;
}

#app-root {
  padding: 0.8rem;
}
